import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const meEndpointWithUniqueParam = appconfig.endpoints.meWithUniqueParam;
const avatarEndpoint = appconfig.endpoints.avatarUrls;
const apiBaseUrl = { baseURL: environment.get().api.serviceUrl };
const meQueryStringEndpoint = appconfig.endpoints.meGraphQlRequest;

export const daoMeGet = () => api.get(meEndpointWithUniqueParam);

export const daoAvatarUrlsGet = () => api.get(avatarEndpoint);

export const daoMeGetWithQueryString = (payload) => api.post(meQueryStringEndpoint, payload, apiBaseUrl);
