import React from "react";
import PropTypes from "prop-types";
import Alert from "components/alert-view";
import { defineMessages, FormattedMessage } from "react-intl";
import appconfig from "config/appconfig";
import { Typography } from "@getgo/chameleon-web-react-wrapper";
import { PasskeyOutlinedIcon } from "@getgo/chameleon-icons/react";
import classes from "./alert.scss";

const t = defineMessages({
  "alert.passkey-signin.description": {
    id: "alert.passkey-signin.description",
    defaultMessage: "Use a passkey to access GoTo in a more convenient and secure way.",
  },
  "common.learn-more": {
    id: "common.learn-more",
    defaultMessage: "Learn more",
    description: "Link text for any anchor tag",
  },
});

export const AlertPasskeyView = ({ handleDismiss, showPasskeyBanner }) => {
  return (
    <React.Fragment>
      {showPasskeyBanner && (<Alert
        type={appconfig.alertTypes.WARNING}
        closable
        icon={<PasskeyOutlinedIcon />}
        body={
          <span className={classes.bannerMessageText}>
            <Typography color="brand-color-black">
              <FormattedMessage
                {...t["alert.passkey-signin.description"]} />
              <a className="learnMoreLink" href="https://support.goto.com/connect/help/create-and-manage-passkeys-for-sign-in" rel="noopener noreferrer" target="_blank">
                <FormattedMessage {...t["common.learn-more"]}/>
              </a>
            </Typography>
          </span>
        }
        handleDismiss={handleDismiss}
      />)}
    </React.Fragment>
  );
};

AlertPasskeyView.propTypes = {
  alertVisisble: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  showPasskeyBanner: PropTypes.bool.isRequired
};

export default AlertPasskeyView;
