import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Core from "../components/core-view";
import {
  uiShowMessageBanner,
  uiMessageBannerVisible,
  uiAlertAuthenticationWarningVisible,
  uiAlertAuthenticationExpiredVisible,
} from "modules/ui-module";
import { meGotoAppBanner ,meLoader, meShowErrorBanner } from "modules/ui-module";
import {meIsConflicted } from "modules/me-module";
import { meRequestGet } from "modules/me-service-module";
import auth from "lib/myaccount-auth";

export const CoreContainer = () => {
  const {
    isUserConflicted,
    meGotoAppBanner,
    meShowErrorBanner,
    meLoader,
    messageBannerVisible,
    alertAuthWarningIsVisible,
    alertAuthExpiredIsVisible,
  } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();
  const [userHasPasskey, setUserHasPasskey] = useState();
  const [passkeyCredentialIsLoading, setPasskeyCredentialIsLoading] = useState(true);

  const logoutUser = useCallback(() => {
    auth.logout();
  }, []);

  const handleSignOut = useCallback(()=>{
    logoutUser();
  },[logoutUser]);

  useEffect(()=>{
    const queryString = encodeURIComponent("{user {passKeyBannerClosedAt}}");
    dispatch(meRequestGet(queryString)).then(
      (res) => {
        const userAlreadyClosedBanner = !!(res?.payload?.user?.passKeyBannerClosedAt || false);
        if(userAlreadyClosedBanner) {
          setUserHasPasskey(true);
        }
        else {
          setUserHasPasskey(false);
        }
        setPasskeyCredentialIsLoading(false);
      },
      () => {
        setPasskeyCredentialIsLoading(false);
      }
  );
  },[]);

  useEffect(() => {
    if (isUserConflicted) {
      dispatch(
        uiShowMessageBanner("userIsConflicted", {
          onclick: handleSignOut ,
        })
      );
    }
  }, [isUserConflicted, dispatch, handleSignOut]);

  return (
    <Core
      meGotoAppBanner={meGotoAppBanner}
      meShowErrorBanner={meShowErrorBanner}
      meLoader={meLoader}
      userHasNoPasskey={!userHasPasskey && !passkeyCredentialIsLoading}
      messageBannerVisible={messageBannerVisible}
      alertAuthWarningIsVisible={alertAuthWarningIsVisible}
      alertAuthExpiredIsVisible={alertAuthExpiredIsVisible}
    />
  );
};
export const getValuesFromStore = (state) => {
  return{
  isUserConflicted: meIsConflicted(state),
  meGotoAppBanner:  meGotoAppBanner(state),
  meShowErrorBanner: meShowErrorBanner(state),
  meLoader: meLoader(state),
  messageBannerVisible: uiMessageBannerVisible(state),
  alertAuthWarningIsVisible: uiAlertAuthenticationWarningVisible(state),
  alertAuthExpiredIsVisible: uiAlertAuthenticationExpiredVisible(state),
};
};

export default CoreContainer;
