import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import MainNav from "core/containers/main-nav-container";
import classes from "./core-view.scss";
import "styles/core.scss";
import { injectIntl } from "react-intl";
import ErrorView from "routes/error/containers/error-container";
import HomeContainer from "routes/products/containers/home-container";
import SideNav from "./side-nav-view";
import SideDrawerContainer from "../containers/side-drawer-container";
import AppThemeProvider from "./appThemeProvider";
import GotoAppBanner from "../containers/gotoapp-banner-container";
import ErrorBanner from "./error-banner";
import Announcement from "../containers/announcement-container";
import MessageBanner from "containers/message-banner-container";
import AlertAuthWarning from "containers/alert-auth-warning-container";
import AlertAuthExpired from "containers/alert-auth-expired-container";
import AlertPasskeyContainer from "containers/alert-passkey-container";
import { Box } from "@mui/material";

const Core = ({
  meGotoAppBanner,
  meShowErrorBanner,
  messageBannerVisible,
  alertAuthWarningIsVisible,
  alertAuthExpiredIsVisible,
  userHasNoPasskey
}) => {

  return (
    <AppThemeProvider>
      <Box id="app-container">
        <Box className="app-container-inner">
          <Box className={classes["bannerDisplay"]}>
            {userHasNoPasskey && <AlertPasskeyContainer />}
            {alertAuthWarningIsVisible && <AlertAuthWarning />}
            {alertAuthExpiredIsVisible && <AlertAuthExpired />}
            {meGotoAppBanner && <GotoAppBanner />}
            {meShowErrorBanner ? <ErrorBanner /> : <Announcement />}
            {messageBannerVisible && <MessageBanner />}
          </Box>
          <MainNav />
          <Box className={classes["fluidAppContainer"]}> 
            <Box className={classes['mainDisplay']}>  
              <SideNav />
              <Box className={classes['prodContainer']}>             
                <Switch>
                  <Redirect exact from="/" to="/products" />
                  <Route exact path="/products" component={HomeContainer} />
                  <Route exact path="/error" component={ErrorView} />
                </Switch>
                <SideDrawerContainer />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AppThemeProvider>
  );
};

Core.propTypes = {
  meGotoAppBanner: PropTypes.bool.isRequired,
  meShowErrorBanner: PropTypes.bool.isRequired,
  messageBannerVisible: PropTypes.bool.isRequired,
  alertAuthWarningIsVisible: PropTypes.bool.isRequired,
  alertAuthExpiredIsVisible: PropTypes.bool.isRequired,
  userHasNoPasskey: PropTypes.bool.isRequired
};

export default withRouter(injectIntl(Core));
