import React  from "react";
import PropTypes from "prop-types";
import classes from "./alert.scss";
import appconfig from "config/appconfig";
import { Banner as ChameleonAlert } from "@getgo/chameleon-web-react-wrapper";

export const Alert = ({ type, icon, body, handleDismiss, action, closable}) => (
     <ChameleonAlert 
      variant={type} 
      closable={closable}
      icon={icon}
      action={action}
      onClose={handleDismiss}>
        {body}
     </ChameleonAlert>
);

Alert.defaultProps = {
  type: appconfig.alertTypes.WARNING,
  handleDismiss: () => { },
};

Alert.propTypes = {
  type: PropTypes.oneOf(["warning", "danger", "success", "info"]),
  icon: PropTypes.any,
  body: PropTypes.any.isRequired,
  handleDismiss: PropTypes.func,
  action: PropTypes.element,
  closable: PropTypes.bool
};

export default Alert;
