// NOTE: this module is different from me-module.js. 
// This makes calls to a server application that provides a GraphQL HTTP API for querying the graph of data associated with an authentication user. 
// Read more at https://confluence.ops.expertcity.com/display/BP/Me+Service

import {
    daoMePost,
    daoMeGet
  } from 'dao/me-service-dao';
  
  // ------------------------------------
  // Constants
  // ------------------------------------
  export const ME_REQUEST_GET = 'ME_REQUEST_GET';
  export const ME_REQUEST_GET_SUCCESS = 'ME_REQUEST_GET_SUCCESS';
  export const ME_REQUEST_GET_FAILURE = 'ME_REQUEST_GET_FAILURE';
  
  export const ME_REQUEST_POST = 'ME_REQUEST_POST';
  export const ME_REQUEST_POST_SUCCESS = 'ME_REQUEST_POST_SUCCESS';
  export const ME_REQUEST_POST_FAILURE = 'ME_REQUEST_POST_FAILURE';
  
  // ------------------------------------
  // Initial State
  // ------------------------------------
  
  export const initialState = {
    // put your module data under this object
    data: {
      user: {
        passkeyBannerClosedAt: ''
      }
    },
    isLoading: false
  };
  
  
  // ------------------------------------
  // Selectors
  // ------------------------------------
  export const meIsLoading = (state) => state.meService.isLoading;
  
  export const meData = (state) => state.meService.data;
  
  
  // ------------------------------------
  // Actions
  // ------------------------------------
  export const meRequestGetReceiveSuccess = (payload = {}) => ({
    type: ME_REQUEST_GET_SUCCESS,
    payload,
  });
  
  export const meRequestGetReceiveFailure = (payload = {}) => ({
    type: ME_REQUEST_GET_FAILURE,
    payload,
  });
  
  export const meRequestGet = (queryString = '') => dispatch => {
    dispatch({
      type: ME_REQUEST_GET,
    });
    return daoMeGet(queryString)
      .then(
        response => dispatch(meRequestGetReceiveSuccess(response.data.data)),
        ex => dispatch(meRequestGetReceiveFailure(ex)),
      );
  };
  
  export const meRequestPostReceiveSuccess = (payload = {}) => ({
    type: ME_REQUEST_POST_SUCCESS,
    payload,
  });
  
  export const meRequestPostReceiveFailure = (payload = {}) => ({
    type: ME_REQUEST_POST_FAILURE,
    payload,
  });
  
  export const meRequestPost = (payload = {}) => dispatch => {
    dispatch({
      type: ME_REQUEST_POST,
    });
    return daoMePost(payload)
      .then(
        response => dispatch(meRequestPostReceiveSuccess(response.data.data)),
        ex => dispatch(meRequestPostReceiveFailure(ex)),
      );
  };
  
  // ------------------------------------
  // Action Handlers
  // ------------------------------------
  const ACTION_HANDLERS = {
  
    [ME_REQUEST_GET]: (state) => ({ ...state, isLoading: true }),
  
    [ME_REQUEST_GET_SUCCESS]: (state, action) => {
      if (typeof action.payload === 'object') {
        return {
          ...state,
          me: { 
            passkeyBannerClosedAt: action?.payload?.data,        },
            isLoading: false,
        };
      }
      return { ...state, isLoading: false };
    },
  
    [ME_REQUEST_GET_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
  
    [ME_REQUEST_POST]: (state) => ({ ...state, isLoading: true }),
  
    [ME_REQUEST_POST_SUCCESS]: (state, action) => {
      if (typeof action.payload === 'object') {
        return {
          ...state,
          me:{ 
            passkeyBannerClosedAt: action?.payload?.data 
          },
          isLoading: false,
        };
      }
      return { ...state, isLoading: false };
    },
  
    [ME_REQUEST_POST_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
  };
  
  // ------------------------------------
  // Reducer
  // ------------------------------------
  const meServiceReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
  };
  
  export default meServiceReducer;
  