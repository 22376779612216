import React, { useState } from "react";
import AlertPasskey from "components/alert-passkey-view";
import { meRequestPost } from "modules/me-service-module";
import { useDispatch } from "react-redux";


export const AlertPasskeyContainer = () => {
  const dispatch = useDispatch();

  const [showPasskeyBanner, setShowPasskeyBanner] = useState(true);
  const handleAlertDismiss = () => {
    const currentDate = Date.now();
    const payload = {
      query: `mutation { updateUser(input: { passKeyBannerClosedAt: "${currentDate}" }) }`
    };
    dispatch(meRequestPost(payload)).then(() => {
      setShowPasskeyBanner(false);
    });
  };

  return (
    <AlertPasskey
      handleDismiss={handleAlertDismiss}
      showPasskeyBanner={showPasskeyBanner}
    />);
};

export default AlertPasskeyContainer;