import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const meQueryStringEndpoint = appconfig.endpoints.meGrapQlRequestWihQuery;
const meGraphQlRequest =  appconfig.endpoints.meGraphQlRequest;
const apiBaseUrl = { baseURL: environment.get().api.serviceUrl };

export const daoMePost = (payload) => api.post(meGraphQlRequest, payload, apiBaseUrl);

export const daoMeGet = (queryString = '') => api.get(meQueryStringEndpoint(queryString), apiBaseUrl);